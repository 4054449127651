import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 2, retryDelay: (retryCount) => {
    return retryCount * 1000;
}});
  

const LIMIT = 3
const cancelTokens = {};

const URI = `${window.location.protocol}//${window.location.hostname}/api`;

export async function fetchGifs(text, filter, offset, limit=LIMIT) {
    const showsFilter = filter ? `shows=[${filter.map(s => '"' + s + '"')}]&` : "";
    return await fetchGifHub(`/gif?text=${text}&${showsFilter}offset=${offset}&limit=${limit}`);
}

export async function fetchShows() {
    return await fetchGifHub("/show");
}

export async function fetchSingleGif(id, offset) {
    return await fetchGifHub(`/gif/${id}?offset=${offset}`);
}

async function fetchGifHub(path) {
    const tokenId = path.split("/")[1];
    if (tokenId in cancelTokens) {
        cancelTokens[tokenId].cancel("Operation canceled due to new request.");
    }
    cancelTokens[tokenId] = axios.CancelToken.source();

    const response = await axios.get(`${URI}${path}`, {cancelToken: cancelTokens[tokenId].token, withCredentials: false});
    return await response.data;
}

export async function getErrorGif() {
    const response = await fetch(require("./assets/error.mp4"));
    const blob = await response.blob();
    const base64Gif = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
    });
    return base64Gif;
}