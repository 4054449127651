import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Gifs.css"
import {fetchGifs, getErrorGif, fetchSingleGif} from "../utils";
import { convertToGif } from "./hooks/Ffmpeg";
import { useSwipe } from "./hooks/Swipe";
import axios from "axios";

const GIF_PREFIX = "data:video/mp4;base64,"

async function download(name, buildURL) {
        const url = await buildURL();
        // Trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
  
        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
}

const Gifs = ({text, filter}) => {
  const [gifs, setGifs] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const swipeHandler = useSwipe((index, offset) => fetchSingleGif(gifs[index].id, offset).then(gif => {
    setGifs((currGifs) => Object.assign([], currGifs, {[index]: gif}));}).catch(console.error));
  const currentProps = useRef();
  currentProps.current = {text, filter};
  const isStale = () => currentProps.current.text !== text || currentProps.current.filter !== filter; 

  const fetchMoreData = (offset) => fetchGifs(text, filter, offset).then(data => {
    if(isStale()) {
      return;
    }
    const newGifs = data["gifs"];
    setGifs((currGifs) => [...currGifs, ...newGifs]);
    setOffset(data["next"]);
    setHasMore(data["hasNext"]);
  }).catch(async e => {
    console.error(e);
    if(isStale() || e instanceof axios.CanceledError) {
      return;
    }
    const gifData = await getErrorGif();
    const errorGif = {data: gifData.slice(GIF_PREFIX.length), name: e.message, season: 1, episode: 9}
    // Remove the gif prefix
    setGifs([errorGif]);
    setHasMore(false);
  });

  // Trigger a scoll event in case the gifs didn't fill the view point
  useEffect(() => {window.dispatchEvent(new CustomEvent('scroll'))}, [gifs])

  useEffect(() => {
    setGifs([]);
    setOffset(0);
    setHasMore(!!text);
    if(text) {
      const getData = setTimeout(() => fetchMoreData(0), 2000);
      return () => clearTimeout(getData);
    }
  }, [text, filter]);

  return (
    <div className="gifs-container">
      <InfiniteScroll
        dataLength={gifs.length}
        // Fetch more only if the first gif was already fetched
        next={() => gifs.length > 0 && fetchMoreData(offset)}
        hasMore={hasMore}
        loader={<LoadingSpinner/>}>
      <div className="gif-grid">
        {gifs.map((gif, index) => (
            <Gif key={`${gif.id}-${index}`} text={text} gif={gif} props={swipeHandler(index)} />
          ))}
      </div>
      </InfiniteScroll>
    </div>
  );
};

const Gif = ({text, gif, props}) => {
  const [inputText, setInputText] = useState("");
  const [displayInput, setDisplayInput] = useState(false);
  // TODO: Display indication that we're loading
  const [downloading, setDownloading] = useState(false);
  

  const onGifRightClick = (event) => {
    event.preventDefault();
    setDisplayInput(true);
  }

  const downloadGif = () => download(`gifhub-${text}.gif`, () => convertToGif(gif.data, inputText));
  const onEnter = (e) => e.key === "Enter" && downloadGif();

  return (<a {...props} onBlur={() => setDisplayInput(!!inputText)} onContextMenu={onGifRightClick} className="gif-container">
              <video onClick={downloadGif} className="gif" loop autoPlay muted playsInline disablePictureInPicture controlsList="nodownload nofullscreen noremoteplayback">
                <source src={`${GIF_PREFIX}${gif.data}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {displayInput ? <input autoFocus onKeyDown={onEnter} className="gif-input-text" type="text" value={inputText} onChange={(e) => setInputText(e.target.value)} /> : <></>}
              {downloading ? <LoadingSpinner cssOverride={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, margin: "auto"}} /> : <img src={require("../assets/download.svg").default} className="gif-download" alt="download" />}
              <div className="gif-metadata"><div>{gif.name}</div><div>Season: {gif.season}</div><div>Episode: {gif.episode}</div></div>
              <div className="swipe">
                  <div className="path"/>
                  <div className="hand-icon"/>
              </div>
          </a>)
};

export default Gifs;