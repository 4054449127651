import { useState } from "react";

export function useSwipe(onSwipe) {
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50
    const onTouchStart = (e) => {
      setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0].clientX);
    }
    
    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
    
    const onTouchEnd = (props) => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe || isRightSwipe) {
        console.log('swipe', isLeftSwipe ? 'left' : 'right');
        onSwipe(props, isLeftSwipe - isRightSwipe);
      }
    }
    return (props) => {return {
      onTouchStart,
      onTouchMove,
      onTouchEnd: () => onTouchEnd(props),
    }};
  }