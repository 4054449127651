import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Filter.css";
import { fetchShows } from "../utils";

const Filter = ({setFilter}) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        async function replace() {
            const data = await fetchShows();
            const shows = data.shows.map(show => {return { label: show, value: show }});
            setOptions(shows);
        }
        replace().catch(console.log);
    }, []);
    return (
        <Select 
        closeMenuOnSelect={false}
        defaultValue={[]}
        isMulti
        placeholder={"Select shows"}
        isLoading={!options.length}
        options={options}
        classNamePrefix={"react-select"}
        className={"filter"}
        isSearchable
        onChange={(options) => setFilter(options.map(o => o.value))} />
    );
}

export default Filter;