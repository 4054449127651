import { useState } from 'react';
import './App.css';
import "./BuyMeCoffee/BuyMeCoffee";
import Gifs from './Gifs/Gifs';
import Filter from './Filter/Filter';
import SearchBox from './SearchBox/SearchBox';

function App() {
  const [text, setSeachText] = useState();
  const [filter, setFilter] = useState();
  return (
    <div>
      <header className="App-header">
        Find A Moment From A TV Show
      </header>
      <div className={"App-body"}>
        <div className={"App-search"}>
          <SearchBox onInputChange={setSeachText} />
          <Filter setFilter={setFilter}/>
        </div>
        <Gifs text={text} filter={filter} />
      </div>
    </div>
  );
}

export default App;
