import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';

const ffmpeg = new FFmpeg();
var loaded = false;

const load = async () => {
  const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
  console.log("Loading FFMPEG");
  ffmpeg.on('log', console.log);
  // toBlobURL is used to bypass CORS issue, urls with the same
  // domain can be used directly.
  await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
  });
  loaded = true;
}

const createFontFile = async () => {
  const fileName = "ARIAL.TTF";
  const fontResponse = await fetch(require(`../../assets/${fileName}`));
  const fontBytes = new Uint8Array(await fontResponse.arrayBuffer());

  await ffmpeg.writeFile(fileName, fontBytes);
  return fileName;
}

const drawText = (text, fontfile) => {
  const replace_pairs = {"\\": "\\\\", ":": "\\:", "'": "'\\\\\\''"}
  Object.keys(replace_pairs).forEach(replacement => {
    text = text.replace(replacement, replace_pairs[replacement]);
  });
  
  const filterJson = {
    text: `'${text}'`,
    text_shaping: 1,
    fontfile: fontfile,
    fontcolor: "white",
    fontsize: 24,
    borderw: 2,
    bordercolor: "black",
    x: "(w-text_w)/2",
    y: "(h-text_h)*0.1",
  }
  const filter = Object.entries(filterJson).map(([key, value]) => `${key}=${value}`).join(':');
  return `drawtext=${filter}`
}

const transcode = async (video, props) => {
  // Decode base64 string to binary data
  const binaryString = atob(video);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  await ffmpeg.writeFile('input.mp4', bytes);
  const font = await createFontFile();
  const result = await ffmpeg.exec(['-i', 'input.mp4', "-vf", drawText(props, font), 'output.gif']);
  if(result !== 0){
    throw new Error("Failed to convert video into Gif");
  }
  const data = await ffmpeg.readFile('output.gif'); 
  return URL.createObjectURL(new Blob([data.buffer], {type: 'image/gif'}));
}

export async function convertToGif(video, props) {
  if(!loaded) {
    await load();
  }
  return await transcode(video, props);
}