import React, { useState } from 'react';
import "./SearchBox.css"

const SearchBox = ({ onInputChange }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  return (
    <input
      className="search-box"
      type="text"
      value={inputValue}
      onChange={handleChange}
      placeholder="Quote a TV show scene"
    />
  );
};

export default SearchBox;